import React from "react";
import { Input } from "../../other/Input";
import { SectionComplianceFilter } from "../../sideBySide/SideBySideContentForReport";
import { FaSearch } from "react-icons/fa";
import "./reportResultFilter.scss";
import { ReviewResultTypeFilter } from "./ReviewResultTypeFilter";
import { useRecoilState } from "recoil";
import { planReportFiltersAtom } from "../atom/PlanReportFiltersAtom";
import { PageNumberFilter } from "./PageNumberFilter";

export const ReportResultFilter = () => {
  const [{ searchText }, setPlanReportFilters] = useRecoilState(
    planReportFiltersAtom,
  );
  const updateInput = (searchText: string) => {
    setPlanReportFilters((old) => ({ ...old, searchText }));
  };

  return (
    <div className="report-result-filter">
      <div className="title">Filters</div>
      <div className="flex filters">
        <div className="filter-container">
          <Input
            value={searchText}
            onChange={updateInput}
            placeholder="Search"
            IconLeft={FaSearch}
          />
        </div>
        <div className="filter-container">
          <ReviewResultTypeFilter />
        </div>
      </div>
    </div>
  );
};
