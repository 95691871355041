export const DATA_EL_TYPE = {
  BUILDING_CODE: "code-section",
  PAGE_CITATION: "page-citation",
  COMPONENT_CITATION: "component-citation",
  CITY_NAME: "city-name",
  REVIEW_RESULT: "review-result",
};

export const DATA_ATTRIBUTES = {
  TYPE: "data-ref-type",
  BUILDING_CODE: "data-building-code-ref",
  PAGE: "data-page-ref",
  COMPONENT_ID: "data-component-id",
  COMPONENT_TITLE: "data-component-title",
  CITY_NAME: "data-city-name",
};
