import React, { useState } from "react";
import { planReportApi } from "../../api/planReportApi";
import { FullPlanReport } from "../../model/blueprintServer";
import { Button } from "../theme/Button";
import classNames from "classnames";
import {
  getReportResultClassForStr,
  getReportResultText,
} from "../planReport/reportUtil";
import useIsLoad from "./useIsLoad";
import { SpinnerLoader } from "../other/SpinnerLoader";
import { FcCheckmark } from "react-icons/fc";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";
import { BsRecycle } from "react-icons/bs";
import "./adminRerunReport.scss";
import { niceDate } from "../../util/dateUtil";

export const AdminRerunReport = () => {
  const [reportId, setReportId] = useState("");
  const [fullReport, setFullReport] = useState<FullPlanReport | null>(null);
  const { isLoading, setLoadStatus } = useIsLoad();
  const [sectionPath, setSectionPath] = useState("");
  const [codeYear, setCodeYear] = useState<number | null>(null);
  const [error, setError] = useState("");

  const reRunSectionComplianceReport = (sectionComplianceReportId: string) => {
    setLoadStatus(sectionComplianceReportId, true);
    planReportApi
      .adminReplaceSectionComplianceReport(sectionComplianceReportId)
      .then((sectionComplianceReport) => {
        console.log(sectionComplianceReportId);
        loadReport();
      })
      .finally(() => {
        setLoadStatus(sectionComplianceReportId, false);
      });
  };

  const loadReport = () => {
    setLoadStatus("report", true);
    planReportApi
      .getReportAdmin(reportId)
      .then((fullReport) => {
        setFullReport(fullReport);
      })
      .finally(() => {
        setLoadStatus("report", false);
      });
  };

  const sections = () => {
    if (!fullReport) {
      return null;
    }

    return fullReport.sectionComplianceReportList.map((section) => {
      return (
        <tr key={section.sectionComplianceId} className="flex">
          <td style={{ width: "100px" }}>
            <span
              className={classNames(
                getReportResultClassForStr(section.finalAssessment),
                "analysis-compliance",
              )}
            >
              {getReportResultText(section.finalAssessment)}
            </span>
          </td>
          <td style={{ width: "80px" }}>
            {section.buildingCode.fullSectionPath}
          </td>
          <td>{niceDate(section.created)}</td>
          <td>{section.error ? "Error" : ""}</td>
          <td>
            {section.rerun ? (
              <span>
                Re-run
                <BsRecycle />
              </span>
            ) : (
              <span>
                Original
                <FcCheckmark />
              </span>
            )}
          </td>
          <td>
            {section.active ? (
              <span>
                Active
                <FcCheckmark />
              </span>
            ) : (
              <span>
                Inactive
                <HiOutlineExclamationTriangle />
              </span>
            )}
          </td>
          <td>
            <Button
              disabled={
                !section.active || isLoading(section.sectionComplianceId)
              }
              onClick={() =>
                reRunSectionComplianceReport(section.sectionComplianceId)
              }
            >
              {isLoading(section.sectionComplianceId) ? (
                <SpinnerLoader />
              ) : (
                "Re-run"
              )}
            </Button>
          </td>
        </tr>
      );
    });
  };

  const isAddSectionDisabled = () => !codeYear || !sectionPath;

  const handleAddSection = () => {
    if (isAddSectionDisabled()) {
      return null;
    }

    setError("");
    setLoadStatus("report", true);
    planReportApi
      .adminAddSectionComplianceReport(reportId, sectionPath, codeYear ?? 0)
      .then(() => {})
      .catch((e) => {
        console.error(e);
        setError("Failed to add a section");
      })
      .finally(() => {
        setLoadStatus("report", false);
      });
  };

  const addSection = () => {
    if (!fullReport) {
      return null;
    }

    return (
      <div>
        <h2>Add section</h2>
        <div>{error ? error : ""}</div>
        <div>
          <input
            placeholder="Section path"
            onChange={(e) => setSectionPath(e.target.value)}
            value={sectionPath}
          />
          <input
            placeholder="Code year"
            onChange={(e) => setCodeYear(parseInt(e.target.value))}
          />
          <Button onClick={handleAddSection} disabled={isAddSectionDisabled()}>
            Add
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="admin-rerun-report">
      <h2>Rerun report section</h2>
      <div className="flex">
        <input onChange={(e) => setReportId(e.target.value)} />
        <Button onClick={loadReport}>Search</Button>
      </div>
      <div>{isLoading("report") ? <SpinnerLoader /> : ""}</div>
      {addSection()}
      {fullReport && <h2>Report</h2>}
      <table className="container">{sections()}</table>
    </div>
  );
};
