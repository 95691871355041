import React, { Component } from "react";

export class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }
  componentDidCatch(error: any, info: any) {
    console.error("App crash");
    console.error(error);
  }
  render() {
    if (this.state.hasError) {
      return <div>The app crashed</div>;
    }
    return this.props.children;
  }
}
