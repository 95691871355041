import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import "./dropdown.scss";
import { FaAngleDown } from "react-icons/fa";
import { IconType } from "react-icons";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { useHideOnClickOutside } from "./useHideOnClickOutside";
import { useClickOnDropdown } from "./useClickOnDropdown";

const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return String(hash);
};

export interface DropdownBaseProps<T> {
  value: T | null;
  onChange: (val: T) => void;
  valueFn?: (val: T | null) => string;
  IconLeft?: IconType;
  placeholder?: ReactNode;
  disabled?: boolean;
}

interface DropdownProps<T> extends DropdownBaseProps<T> {
  searchFn?: (item: T, query: string) => boolean;
  values: T[];
}

export const Dropdown = <T,>({
  values,
  value,
  onChange,
  valueFn,
  searchFn,
  IconLeft,
  placeholder,
  disabled = false,
}: DropdownProps<T>) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = useClickOnDropdown(ref, setIsOpen);
  useHideOnClickOutside(ref, setIsOpen);

  const key = useMemo(
    () => hashCode(values.join(",") + Math.random()),
    [values],
  );

  const filterValues = useMemo(() => {
    if (!searchFn) {
      return values;
    }

    return values.filter((item) => searchFn(item, query));
  }, [values, searchFn, query]);

  const displayValue = useMemo(() => {
    if (value === null || value === undefined) {
      return placeholder ?? "";
    }

    if (valueFn) {
      return valueFn(value);
    }

    return value as string;
  }, [value, valueFn, placeholder]);

  const searchField = useMemo(() => {
    if (!searchFn) {
      return null;
    }

    return (
      <input
        onMouseEnter={(e) => (e.target as HTMLDivElement).focus()}
        placeholder="Search"
        onChange={(e) => setQuery(e.target.value)}
        className="input"
      />
    );
  }, [searchFn, setQuery]);

  const renderOption = useCallback(
    (option: T, index: number) => (
      <div
        className="item"
        key={index}
        onClick={() => {
          setIsOpen(false);
          onChange(option);
        }}
      >
        <span>{valueFn ? valueFn(option) : (option as string)}</span>
      </div>
    ),

    [valueFn, onChange, setIsOpen],
  );

  const dropdownContent = useMemo(() => {
    if (disabled) {
      return null;
    }

    return (
      <Tooltip id={key} place="bottom-start" clickable isOpen={isOpen}>
        <div className="dropdown-content">
          <div className="inner">
            {searchField}
            <div className="list">
              {filterValues.map((option, i) => renderOption(option, i))}
            </div>
          </div>
        </div>
      </Tooltip>
    );
  }, [disabled, filterValues, searchField, key, renderOption, isOpen]);

  return (
    <div
      className="dropdown"
      ref={ref}
      data-tooltip-id={key}
      onClick={handleClick}
    >
      <span className="value-container">
        <div className="value flex">
          {IconLeft && <IconLeft className={classNames({ text: !!value })} />}
          <span>{displayValue}</span>
        </div>
        <FaAngleDown />
      </span>
      {dropdownContent}
    </div>
  );
};
