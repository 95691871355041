import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";
import { authApi } from "../api/authApi";
import { useRecoilState } from "recoil";
import { serverAuthFinishedAtom } from "../atom/serverAuthFinishedAtom";
import { DataState } from "../atom/projectsAtom";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTER_PATHS } from "../components/app/routerPaths";
import Intercom from "@intercom/messenger-js-sdk";

export const useAuth = () => {
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout: authLogout,
  } = useAuth0();
  const [serverAuthState, setServerAuthState] = useRecoilState(
    serverAuthFinishedAtom,
  );
  const [searchParams] = useSearchParams();
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    authLogout({ logoutParams: { returnTo: "https://blueplanai.com" } });
  };

  const doAuth = useCallback(() => {
    const asyncFn = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token && user) {
          setServerAuthState({
            state: DataState.LOADING,
            appLevelUser: undefined,
          });
          const appUser = await authApi.auth(token, user.org_id);
          setServerAuthState({
            state: DataState.LOADED,
            appLevelUser: appUser,
          });
          // @ts-ignore
          Intercom({
            app_id: "wnro1x33",
            user_id: appUser.oktaUserId,
            name: appUser.name,
            email: appUser.email,
            created_at: new Date(appUser.updated_at ?? new Date()).getTime(),
            user_hash: appUser.intercomHash,
          });
        } else if (!matchPath({ path: ROUTER_PATHS.ROOT }, location.pathname)) {
          navigate(ROUTER_PATHS.HOMEPAGE);
        }
      } catch (e: any) {
        if (
          e.error === "login_required" ||
          e.error === "interaction_required"
        ) {
          console.error("error login: ", e);
          return;
        }
        setServerAuthState({
          state: DataState.FAILED,
          appLevelUser: undefined,
        });
      }
    };
    if (isAuthenticated) {
      asyncFn();
    } else if (isLoading) {
      // NOOP
    } else {
      loginWithRedirect();
    }
  }, [
    getAccessTokenSilently,
    setServerAuthState,
    loginWithRedirect,
    navigate,
    location.pathname,
    isLoading,
    user,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (searchParams.get("error") === "invalid_request") {
      setServerAuthState({
        state: DataState.FAILED,
        appLevelUser: undefined,
      });
      localStorage.removeItem("authToken");
    }
  }, [searchParams, setServerAuthState]);

  return {
    isAuth: isAuthenticated && serverAuthState.state === DataState.LOADED,
    isLoading: isLoading || serverAuthState.state === DataState.LOADING,
    user,
    doAuth,
    logout,
  };
};
