import React, { useState } from "react";
import { adminApi } from "../../api/adminApi";
import { Button } from "../theme/Button";

interface CreateSubComponentButtonProps {
  planId: string;
  pageNumber: number;
  componentId: string;
}

export const CreateSubComponentButton = ({
  planId,
  pageNumber,
  componentId,
}: CreateSubComponentButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const createSubComponent = async () => {
    setIsLoading(true);
    await adminApi.createSubComponent(planId, pageNumber, componentId);
  };
  return (
    <Button onClick={createSubComponent}>
      {isLoading ? "Loading" : "Create sub-comp"}
    </Button>
  );
};
