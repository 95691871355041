import { MutableRefObject, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { useBrowserEventListenerEvent } from "../../hooks/useBrowserEventListenerEvent";
import { Size } from "../../model/math";

export const useCanvasSizeAndScale = (
  size: Size | null,
  setSize: any,
  canvasRef: MutableRefObject<HTMLCanvasElement | null>,
) => {
  const [{ scale, originalImageWidth, originalImageHeight }, setImageSize] =
    useRecoilState(pageImageSizeAtom);

  const dpi = window.devicePixelRatio || 1;
  const scaleOverDpi = scale / dpi;

  const handleContainerChange = useCallback(() => {
    if (!canvasRef.current || !canvasRef.current?.parentElement) {
      return;
    }

    if (!size) {
      return;
    }

    const canvas = canvasRef.current;
    const parentEl = canvas.parentElement;
    if (!canvas || !parentEl) {
      return;
    }

    const { width, height } = size;
    const containerWidth = canvas.parentElement.getBoundingClientRect().width;
    const scale = containerWidth > width ? 1 : (containerWidth / width) * dpi;
    const adjustedContainerWidth = containerWidth;
    const adjustedContainerHeight = (height * scale) / dpi;
    const adjustedVideoHeight = Math.min(adjustedContainerHeight, height);
    const adjustedVideoWidth = Math.min(adjustedContainerWidth, width);

    canvas.style.width = `${adjustedContainerWidth}px`;
    canvas.style.height = `${adjustedContainerHeight}px`;

    canvas.width = adjustedContainerWidth * dpi;
    canvas.height = adjustedContainerHeight * dpi;

    setImageSize({
      containerWidth,
      scale,
      adjustedContainerWidth,
      adjustedContainerHeight,
      adjustedVideoWidth,
      adjustedVideoHeight,
      originalImageWidth: width,
      originalImageHeight: height,
    });
  }, [setImageSize, canvasRef, size, dpi]);

  useEffect(() => {
    handleContainerChange();
  }, [handleContainerChange]);

  useBrowserEventListenerEvent("resize", handleContainerChange, true);

  return { dpi, scaleOverDpi, scale, originalImageWidth, originalImageHeight };
};
