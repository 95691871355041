/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2024-12-07 22:10:27.

export interface Diagnostic {
  llmCallDiagnostic?: LlmCallDiagnostic;
  planId?: string;
  planQaId?: string;
  projectId?: string;
  reportId?: string;
  codeYear?: number;
  city?: string;
  fullSectionPath?: string;
  updateComponentManually?: UpdateComponentManually;
  diagnosticId: string;
  resourceOwner?: ResourceOwner;
  created: Date;
  session: string;
  type: DiagnosticType;
  reporter: DiagnosticReporter;
  rerunOfSomething?: boolean;
}

export interface DiagnosticReporter {
  name: string;
  version: string;
}

export interface LlmCallDiagnostic {
  systemPrompt: string;
  basePrompt: string;
  additionalMessages: string[];
  imagesUrls: string[];
  result: string;
  model: string;
  topP: number;
  maxTokens: number;
  error: string;
}

export interface UpdateComponentManually {
  componentId: string;
  planId: string;
  pageIndex: number;
  before: ComponentData;
  after: ComponentData;
}

export interface AskQuestionRequest {
  planQaId: string;
  question: string;
  jurisdiction?: Jurisdiction;
}

export interface BlobStorage {
  bucket: string;
  id: string;
  md5: string;
}

export interface CreatePlanQaRequest {
  planId: string;
  jurisdiction?: Jurisdiction;
}

export interface DataSource {
  address: Address;
  bin: number;
  lastFetched?: Date;
  status: DataSourceType;
  dataUrl: string;
}

export interface FullPlanReport extends PlanReport {
  sectionComplianceReportList: SectionComplianceReport[];
}

export interface Images {
  image: BlobStorage;
  thumbnail: BlobStorage;
}

export interface OktaUserInfo {
  oktaOrg?: Organization;
  oktaUserId: string;
  email: string;
  roles: string[];
  intercomHash: string;
  values: { [index: string]: any };
}

export interface PlanAnnotation {
  id: string;
  failed: boolean;
  planQaId: string;
  label: string;
  rect: Rect;
  pageNumber: number;
  image?: BlobStorage;
  ready: boolean;
}

export interface PlanQARequest {
  planQaId: string;
  pageNumber: number;
  rect: Rect;
}

export interface ProjectAndPlan {
  projectModel: ProjectModel;
  planModel: PlanModel;
}

export interface ReportPreset {
  name: string;
  id: string;
  items: SectionAndYear[];
}

export interface SectionAndYear {
  codeYear: number;
  section: string;
}

export interface SignedUrlResponse {
  signedUrl: string;
  id: string;
}

export interface Rect {
  start: XYPoint;
  end: XYPoint;
}

export interface XYPoint {
  x: number;
  y: number;
}

export interface CopyAnnotationRequest {
  planId: string;
  planQaForAnnotationId: string;
  resourceOwner: ResourceOwner;
}

export interface CreateSubComponentsRequest {
  planId: string;
  pageIndex: number;
  parentComponentId: string;
  resourceOwner: ResourceOwner;
}

export interface InitialPlanOnboardingRequest {
  planId: string;
  pdfBlob: BlobStorage;
  resourceOwner: ResourceOwner;
}

export interface ComponentData {
  title: string;
  description: string;
  extractedData: string;
  categories: string[];
}

export interface PageComponentTextualContextRequest {
  componentImageUrl: string;
}

export interface PageComponentTextualContextResponse {
  baseComponent: ComponentData;
}

export interface PlanQuestionRequest {
  planId: string;
  planQaId: string;
  questionId: string;
  imageUrls: string[];
  userQuestion: string;
  jurisdiction: Jurisdiction;
  creator: ResourceOwner;
}

export interface PlanTextualContextRequest {
  pageImageUrls: string[];
}

export interface PlanTextualContextResponse {
  answer: string;
}

export interface PythonCallWithProjectAndPlan<T> {
  project?: ProjectModel;
  plan?: PlanModel;
  data?: T;
  diagnostic?: Diagnostic;
}

export interface SectionComplianceRequest {
  sections: BuildingCodeRecord[];
  reportId: string;
  resourceOwner: ResourceOwner;
  diagnostic: Diagnostic;
}

export interface SegmentPageRequest {
  imageUrl: string;
}

export interface SubComponentTextualContextRequest {
  parentComponentImageUrl: string;
}

export interface SubComponentTextualContextResponse {
  componentRect: Rect[];
  subComponents: ComponentData[];
}

export interface AllBinStatus {
  dobBuildingData: DataSource;
  bisBuildingData: DataSource;
  bisActionData: DataSource;
  bisZoningData: DataSource;
}

export interface BisZoningDataUI {
  filingDataString: string;
  job: ValueWithLink<number>;
  jobType: string;
  jobStatus: string;
  statusDataString: string;
  document: ValueWithLink<string>;
}

export interface GizGetZoningResponse extends DataSource {
  location: any;
  zoningPolygons: ZoningPolygon[];
}

export interface ValueWithLink<T> {
  value: T;
  link?: string;
}

export interface ZoningDocuments {
  name: string;
  formId: string;
  docNumber: number;
  dateScanned: string;
  scanCode: string;
}

export interface CollectComplianceReports {
  reportMap: { [index: string]: SectionComplianceReport };
  buildingCodeMap: { [index: string]: BuildingCodeRecord };
}

export interface PlanReportRequest {
  planId: string;
  cityName: CityName;
  presetId: string;
}

export interface SectionComplianceReasoning {
  requirement: string;
  providedData: string;
  analysis: string;
  evaluation: string;
  sources: string[];
}

export interface SectionComplianceReport {
  sectionComplianceId: string;
  item: string;
  reportId: string;
  analysis: string;
  analysisSummary: string;
  finalAssessment: ReviewResultType;
  buildingCode: BuildingCodeRecord;
  created: Date;
  markedBlueprintLocation: ComponentReference[];
  active: boolean;
  error: boolean;
  rerun: boolean;
}

export interface BuildingCodeRecord {
  id: string;
  chapter: string;
  fullSectionPath: string;
  topSection: string;
  title: string;
  text: string;
  year: number;
  city: CityName;
  type: BuildingCodeType;
}

export interface BuildingCodeReference {
  year: number;
  city: CityName;
  fullSectionPath: string;
}

export interface OpenAIRequest {
  id: string;
  date: Date;
  tokens: number;
  model: string;
}

export interface Address {
  id: string;
  number: number;
  street: string;
  newYorkDetails?: NewYorkDetails;
  philadelphiaDetails?: PhiladelphiaDetails;
  city: CityName;
  realCityName: string;
  state: string;
  zip: number;
  googlePlaceId: string;
  formatted: string;
}

export interface LocalDetails {
  nyBorough: NyBorough;
  philadelphia: boolean;
  ny: boolean;
}

export interface NewYorkDetails {
  nyBorough: NyBorough;
  bin?: number;
}

export interface PhiladelphiaDetails {
  nyBorough: NyBorough;
  bin: number;
}

export interface PlanModel {
  id: string;
  created: Date;
  updated: Date;
  projectId: string;
  pages: PlanPage[];
  status: DataLoadingStates;
  title: string;
  planTextualContext?: string;
  originalFile: BlobStorage;
}

export interface PlanPage {
  id: string;
  created: Date;
  updated: Date;
  planId: string;
  pageNumber: number;
  components: Component[];
  pageImageBlob: BlobStorage;
  thumbnailBlob: BlobStorage;
}

export interface Component {
  componentId: string;
  imageBlobStorage: BlobStorage;
  rect: Rect;
  data: ComponentData;
  parentComponentId?: string;
}

export interface ComponentReference {
  pageNumber: number;
  componentId: string;
}

export interface PlanQA {
  planQaId: string;
  planId: string;
  title: string;
  created: Date;
  planAnnotation: { [index: string]: PlanAnnotation };
}

export interface PlanQuestion {
  jurisdiction: Jurisdiction;
  id: string;
  inquiryId: string;
  question: string;
  answer?: Answer;
  created: Date;
  status: DataLoadingStates;
}

export interface Analysis {
  requirement: string;
  provideddata: string;
  analysis: string;
  evaluation: string;
  sources: string[];
}

export interface Answer {
  answer: string;
  projectScope: string;
  relevantSections: SourceItem[];
  detailedAnalysis: Analysis[];
}

export interface SourceItem {
  section: string;
  sectionTitle: string;
}

export interface ProjectModel {
  address: Address;
  googlePlacesId: string;
  created: Date;
  projectId: string;
  orgId: string;
  creator: string;
  jurisdiction: Jurisdiction;
  projectSize: ProjectSize;
  projectType: string;
  scopes: string[];
  freeformScope: string;
  buildingType: string;
  activePlanId: string;
  buildingSize: number;
}

export interface Jurisdiction {
  cityName: CityName;
  codeYear: number;
}

export interface GeneralPrompt {
  promptKey: string;
  created: Date;
  updated: Date;
  promptId: string;
  head: PromptRecord;
  history: PromptRecord[];
}

export interface PromptRecord {
  recordId: string;
  created: Date;
  text: string;
}

export interface PlanReport {
  reportId: string;
  created: Date;
  status: DataLoadingStates;
  title: string;
  presetId: string;
  planId: string;
}

export interface BisActionData extends DataSource {
  jsonAsStringData: string;
}

export interface BisBuildingData extends DataSource {
  jsonAsStringData: string;
}

export interface BisStopWorkData extends DataSource {
  items: StopWorkItem[];
}

export interface StopWorkItem {
  complaintNumber: number;
  address: string;
  dateEntered: string;
  dateInspection: string;
  category: string;
  disposition: string;
  status: string;
}

export interface BisZoningData extends DataSource {
  documents: { [index: string]: ZoningDocuments[] };
  zoning: BisZoningDataUI[];
}

export interface DobBuildingData extends DataSource {
  jsonAsStringData: string;
}

export interface ZoningPolygon {
  id: string;
  zone: string;
  zoneName?: string;
  dataType: string;
  polygon: any;
  specialPurpose?: SpecialPurpose;
}

export interface SpecialPurpose {
  name: string;
  subDistrict: string;
  subDistrictLabel: string;
  subAreaOther: string;
  subArea: string;
  subAreaName: string;
}

export interface ResourceKey {
  resourceId: string;
  owner: ResourceOwner;
}

export interface ResourceOwner {
  orgId: string;
  userId: string;
  uberRole?: boolean;
}

export interface IdUtil {}

export interface RandomStupidNameGenerator {}

export interface Organization {
  name: string;
  id: string;
  display_name: string;
  metadata: { [index: string]: any };
  branding: Branding;
  /**
   * @deprecated
   */
  enabled_connections: EnabledConnection[];
}

export interface Branding {
  logo_url: string;
  colors: Colors;
}

export interface EnabledConnection {
  connection_id: string;
  connection: Connection;
  assign_membership_on_login: boolean;
  show_as_button: boolean;
}

export interface Colors {
  primary: string;
  page_background: string;
}

export interface Connection {
  name: string;
  strategy: string;
}

export enum DiagnosticType {
  PLAN_QA = "PLAN_QA",
  PLAN_REPORT = "PLAN_REPORT",
  SERVER_START = "SERVER_START",
  UPDATE_COMPONENT = "UPDATE_COMPONENT",
}

/**
 * Values:
 * - `MANHATTAN`
 * - `BROOKLYN`
 * - `BRONX`
 * - `QUEENS`
 * - `STATEN_ISLAND`
 * - `NEW_YORK_CITY`
 * - `PHILADELPHIA`
 * - `NEW_JERSEY`
 * - `PARAMUS` - @deprecated
 */
export enum CityName {
  MANHATTAN = "MANHATTAN",
  BROOKLYN = "BROOKLYN",
  BRONX = "BRONX",
  QUEENS = "QUEENS",
  STATEN_ISLAND = "STATEN_ISLAND",
  NEW_YORK_CITY = "NEW_YORK_CITY",
  PHILADELPHIA = "PHILADELPHIA",
  NEW_JERSEY = "NEW_JERSEY",
  /**
   * @deprecated
   */
  PARAMUS = "PARAMUS",
}

export enum DataLoadingStates {
  INITIAL_PROCESSING = "INITIAL_PROCESSING",
  COMPLETED = "COMPLETED",
  PENDING_DATA_EXTRACTION = "PENDING_DATA_EXTRACTION",
  ERROR = "ERROR",
}

export enum NyBorough {
  BROOKLYN = "BROOKLYN",
  MANHATTAN = "MANHATTAN",
  QUEENS = "QUEENS",
  BRONX = "BRONX",
  ISLAND = "ISLAND",
  NOT_NY = "NOT_NY",
}

export enum DataSourceType {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  FAILED = "FAILED",
}

export enum ReviewResultType {
  COMPLIANCE = "COMPLIANCE",
  NON_COMPLIANCE = "NON_COMPLIANCE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  ON_SITE_VERIFICATION = "ON_SITE_VERIFICATION",
  NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS = "NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS",
  NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO = "NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO",
  NO_ENOUGH_DATA_MISSING_LOCATION_INFO = "NO_ENOUGH_DATA_MISSING_LOCATION_INFO",
  NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION = "NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION",
  NO_ENOUGH_DATA_MISSING_COMPONENTS = "NO_ENOUGH_DATA_MISSING_COMPONENTS",
  NO_ENOUGH_DATA_OTHER = "NO_ENOUGH_DATA_OTHER",
  DEEPER_REVIEW = "DEEPER_REVIEW",
}

export enum BuildingCodeType {
  BUILDING_CODE = "BUILDING_CODE",
}

export enum ProjectSize {
  _1000 = "_1000",
  _5000 = "_5000",
  _10000 = "_10000",
  _20000 = "_20000",
  _50000 = "_50000",
  _100000 = "_100000",
}
