import { OktaOrgMember, organizationApi } from "../api/organizationApi";
import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";

export const membersCacheState = atom<Record<string, OktaOrgMember>>({
  key: "membersCacheState",
  default: {},
});

const DEFAULT: OktaOrgMember = {
  user_id: "-1",
  email: "",
  picture:
    "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541",
  name: "",
};

export const useMemberForResource = (resourceId: string) => {
  const [cache, setCache] = useRecoilState(membersCacheState);

  const fetch = useCallback(async () => {
    if (cache[resourceId]) {
      return;
    }

    setCache((prevCache) => ({
      ...prevCache,
      [resourceId]: DEFAULT,
    }));

    try {
      const fetchedMember = await organizationApi.memberForResource(resourceId);
      setCache((prevCache) => ({
        ...prevCache,
        [resourceId]: fetchedMember,
      }));
    } catch (e) {
      console.error("Failed to fetch icon for resource " + resourceId);
    }
  }, [cache, resourceId, setCache]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return cache[resourceId] || DEFAULT;
};
